<template>
  <v-layout id="login" class="d-flex align-center justify-center gradientBG">
    <img class="logo mr-2" src="@/assets/Youtorial-white.png">
    <v-flex class="xs12 sm10 md4">
      <v-card elevation="10">
          <h2 class="text-center py-3">Log in to YOUTORIAL</h2>
          <div class="error white--text" v-if="errorMessage">
              {{errorMessage}}
          </div>
        <v-card-text>

          <template v-if="showFields">
            <v-text-field
            label="Email"
            v-model="email"
            ></v-text-field>
            <v-text-field
            label="Password"
            v-model="password"
            type="password"
            ></v-text-field>
          </template>

          <template v-else>
            <auth-button :social="'google'" :logInOut="'Continue'" @authWith="signIn"></auth-button>
            <!-- <social-btns :social="'facebook'" :logInOut="'Continue'" @authWith="signIn"></social-btns> -->
            <auth-button :social="'password'" :logInOut="'Continue'" @authWith="showFields = true"></auth-button>
          </template>

          <v-card-actions class="flex-column align-center pa-0">
            <v-btn v-if="showFields" block large class="primary submit" @click="signIn('password')">Sign In</v-btn>
            <v-btn v-if="showFields" block large outlined class=" submit" @click="showFields = false">Show Social Logins</v-btn>

          </v-card-actions>
          <p class="mb-2 mt-4">You don't have an account ? You can <router-link to="/sign-up">create one</router-link></p>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
  const authButton = () => import("@/components/shared/auth-button.vue")

  export default {
    name: 'login',
    metaInfo: {
      title: 'Youtorial: Login'
    },
    components:{
      // socialBtns,
      authButton
    },
    data: function() {
      return {
        email: '',
        password: '',
        showFields: false,
        errorMessage: null,
        kind: null,
      }
    },
    methods: {
      signIn: function(kind) {
        const vThis = this;
        vThis.kind = kind;
        const email = this.email;
        const password = this.password;
        const action = kind !== 'password' ? 'loginSocial' : 'login';
        // console.log('kind', kind);
        this.$store.dispatch(action, {kind, email, password })
        .then((user) => {
          // console.log('user login came back', user);
          vThis.logEvent();
          vThis.$router.push('/')
          // do something with user object
        })
        .catch((err) => {
          this.errorMessage = err;
          console.log(err);
        })
      },
      logEvent(){
        let vThis = this;
        vThis.$logEvent('Account','Login', vThis.kind);
      }
    },
    mounted(){
      this.$store.dispatch('toggleDrawer', false);
    },
  }
</script>

<style lang="scss">
  #login {
    // margin-top: 40px;
    height: 100%;
    position: relative;
    .logo{
      position: absolute;
      top: 10px;
      left: 10px;
      max-width: 220px;
    }
    input {
      margin: 10px 0;
      width: 20%;
      padding: 15px;
    }
    button.submit{
      margin-top: 20px;
      width: 10%;
      cursor: pointer;
    }
    p {
      margin-top: 40px;
      font-size: 13px;
       a {
        text-decoration: underline;
        cursor: pointer;
        }
    }
  }
</style>
